import { multiSelectWithLabel, toNoOptionsMessage, CommonPropsMultiSelect$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { isEmpty, singleton as singleton_1, head, tryFind, find, sortBy, contains, filter, toArray, map, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { StorageId__get_unwrap, FormState__getValidation, LabelId } from "../../Shared/Shared.js";
import { stringHash, comparePrimitives, createObj, safeHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { AreaAnchorMenu } from "../../Widgets/AnchorMenu.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { NewCategoryDialog } from "../../Widgets/Dialogs/NewCategoryDialog.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { FormImageSection, FormSection } from "../../Widgets/FormSection.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { map as map_1, value as value_13, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { CurrencyInput, NumberInput, Input } from "../../Components/Input.js";
import { TextArea } from "../../Components/TextArea.js";
import { Select } from "../../Components/Select.js";
import { Button, TextButton } from "../../Components/Button.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { NewInventoryCustomProperties } from "../../Widgets/CustomProperties.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initCopyView, initNewView, update as update_1, initEditView } from "./State.js";
import Arrow_Left_1 from "../../../public/assets/icons/Arrow_Left_1.svg";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapConsumableId } from "../../Shared/Helper.js";
import { getQueryParams } from "../../Common.js";
import { PageHeader } from "../../Components/Text.js";
import { Skeleton } from "../../Components/Skeleton.js";

export const options = ofArray([new OptionType$1("unit.piece", "piece"), new OptionType$1("unit.package", "package"), new OptionType$1("unit.pair", "pair"), new OptionType$1("unit.millimeter", "millimeter"), new OptionType$1("unit.centimeter", "centimeter"), new OptionType$1("unit.meter", "meter"), new OptionType$1("unit.gram", "gram"), new OptionType$1("unit.kilogram", "kilogram"), new OptionType$1("unit.milliliter", "milliliter"), new OptionType$1("unit.liter", "liter"), new OptionType$1("unit.cubicmetre", "cubicmetre"), new OptionType$1("unit.squaremetre", "squaremetre"), new OptionType$1("unit.roll", "roll"), new OptionType$1("unit.flatrate", "flatRate"), new OptionType$1("unit.pallet", "pallet"), new OptionType$1("unit.bunch", "bunch"), new OptionType$1("unit.woodenbox", "woodenBox"), new OptionType$1("unit.latticebox", "latticeBox")]);

function selectLabelProps(labels, consumable, dispatch, isDisabled) {
    const categoryIds = (consumable.tag === 1) ? map((arg_1) => (new LabelId(parse(arg_1))), consumable.fields[0].CategoryIds) : map((arg) => (new LabelId(parse(arg))), consumable.fields[0].CategoryIds);
    return [new CommonPropsMultiSelect$1(0, [toArray(map((l_1) => (new OptionType$1(l_1.Name, l_1.Id)), filter((l) => !contains(l.Id, categoryIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), labels)))]), new CommonPropsMultiSelect$1(2, [(arg_2) => {
        dispatch(new Msg(10, [arg_2]));
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.label_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.label_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), new CommonPropsMultiSelect$1(7, [isDisabled]), new CommonPropsMultiSelect$1(3, [toArray(map((l_3) => (new OptionType$1(l_3.Name, l_3.Id)), filter((l_2) => contains(l_2.Id, categoryIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), labels)))])];
}

export function ConsumablesForm(props) {
    let elems_3, elems, elems_2;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsNewCateogoryDialogOpen = patternInput_1[1];
    const mandatoryFieldTranslation = t("general.required");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("consumable-masterdata-section");
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,1fr]", "mt-12", "max-w-5xl"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["top-5"])], (elems = [createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "consumable-masterdata-section",
            Label: t("general.master_data"),
        }), delay(() => append(!props.IsEdit ? singleton({
            Id: "consumable-storage-section",
            Label: t("general.storage"),
        }) : empty(), delay(() => append(singleton({
            Id: "consumable-label-section",
            Label: t("tool.label"),
        }), delay(() => append(singleton({
            Id: "consumable-commercial-section",
            Label: t("tool.commercial_data"),
        }), delay(() => (props.IsCustomPropertiesEnabled ? singleton({
            Id: "consumable-nav-header-section",
            Label: t("tool.custom_properties"),
        }) : empty()))))))))))),
        OnSelectAnchor: patternInput_2[1],
        SelectedAnchor: patternInput_2[0],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["id", "tool-form"], ["className", join(" ", ["flex", "flex-col", "gap-9"])], (elems_2 = toList(delay(() => append(singleton(createElement(NewCategoryDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsNewCateogoryDialogOpen(false);
            props.Dispatch(new Msg(12, []));
        },
    })), delay(() => {
        let matchValue;
        return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? (empty()) : singleton(createElement(ErrorAlert, {
            Label: matchValue,
        }))), delay(() => {
            let Header;
            return append(singleton(createElement(FormSection, (Header = t("general.master_data"), {
                Content: toList(delay(() => {
                    const nameTranslation = t("general.name");
                    return append(isFeatureEnabled(new Feature(5, [])) ? (!props.IsEdit ? singleton(createElement(FormImageSection, {
                        FormState: componentState,
                        SelectedFile: unwrap(props.SelectedFile),
                        SetSelectedFile: (file) => {
                            props.Dispatch(new Msg(20, [file]));
                        },
                    })) : empty()) : empty(), delay(() => {
                        let Value, matchValue_1;
                        return append(singleton(createElement(Input, (Value = ((matchValue_1 = props.Consumable, (matchValue_1.tag === 1) ? matchValue_1.fields[0].Name : matchValue_1.fields[0].Name)), {
                            ComponentState: componentState,
                            Label: `${nameTranslation} (${mandatoryFieldTranslation})`,
                            OnChange: (arg) => {
                                props.Dispatch(new Msg(0, [arg]));
                            },
                            TestId: "",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
                            Value: Value,
                        }))), delay(() => {
                            let Label_1, Value_1, matchValue_2;
                            return append(singleton(createElement(Input, (Label_1 = t("consumable.additional_name"), (Value_1 = ((matchValue_2 = props.Consumable, (matchValue_2.tag === 1) ? matchValue_2.fields[0].AdditionalName : matchValue_2.fields[0].AdditionalName)), {
                                ComponentState: componentState,
                                Label: unwrap(Label_1),
                                OnChange: (arg_1) => {
                                    props.Dispatch(new Msg(1, [arg_1]));
                                },
                                TestId: "",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "additionalName")),
                                Value: Value_1,
                            })))), delay(() => {
                                let matchValue_3;
                                return append(singleton(createElement(TextArea, {
                                    ComponentState: componentState,
                                    Label: t("general.description"),
                                    OnChange: (arg_2) => {
                                        props.Dispatch(new Msg(2, [arg_2]));
                                    },
                                    TestId: "",
                                    Value: (matchValue_3 = props.Consumable, (matchValue_3.tag === 1) ? matchValue_3.fields[0].Description : matchValue_3.fields[0].Description),
                                })), delay(() => {
                                    let Label_3, Value_3, matchValue_4;
                                    return append(singleton(createElement(Input, (Label_3 = t("consumable.inventory_number"), (Value_3 = ((matchValue_4 = props.Consumable, (matchValue_4.tag === 1) ? matchValue_4.fields[0].InventoryNumber : matchValue_4.fields[0].InventoryNumber)), {
                                        ComponentState: componentState,
                                        Label: unwrap(Label_3),
                                        OnChange: (arg_3) => {
                                            props.Dispatch(new Msg(3, [arg_3]));
                                        },
                                        TestId: "",
                                        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "inventoryNumber")),
                                        Value: Value_3,
                                    })))), delay(() => {
                                        let Label_4, Options, Value_4, unit_1, matchValue_5;
                                        return singleton(createElement(Select, (Label_4 = t("general.unit"), (Options = sortBy((option) => option.label, map((unit) => ({
                                            label: t(unit.label),
                                            value: unit.value,
                                        }), options), {
                                            Compare: comparePrimitives,
                                        }), (Value_4 = ((unit_1 = ((matchValue_5 = props.Consumable, (matchValue_5.tag === 1) ? matchValue_5.fields[0].Unit : matchValue_5.fields[0].Unit)), {
                                            label: t(find((option_1) => (option_1.value === unit_1), options).label),
                                            value: unit_1,
                                        })), {
                                            ComponentState: componentState,
                                            IsClearable: false,
                                            Label: unwrap(Label_4),
                                            NoOptionsMessage: "",
                                            OnChange: (consumable) => {
                                                props.Dispatch(new Msg(4, [value_13(consumable).value]));
                                            },
                                            Options: Options,
                                            PlaceholderKey: "",
                                            TestId: "",
                                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "unit")),
                                            Value: unwrap(Value_4),
                                        })))));
                                    }));
                                }));
                            }));
                        }));
                    }));
                })),
                Header: Header,
                Id: "consumable-masterdata-section",
            }))), delay(() => {
                let storage_1, matchValue_6, Header_1;
                return append(!props.IsEdit ? ((storage_1 = ((matchValue_6 = props.Consumable, (matchValue_6.tag === 1) ? undefined : tryFind((storage) => (StorageId__get_unwrap(storage.Id) === head(matchValue_6.fields[0].StockPerStorage).StorageId), props.Storages))), singleton(createElement(FormSection, (Header_1 = t("general.storage"), {
                    Content: toList(delay(() => {
                        let Label_5, Options_1, NoOptionsMessage_1, Value_6, someStorage;
                        return append(singleton(createElement(Select, (Label_5 = t("general.storage"), (Options_1 = map((storage_2) => ({
                            label: storage_2.Name,
                            value: storage_2.Id,
                        }), filter((s) => {
                            if (props.UserData.Role === "toolManager") {
                                return contains(props.UserData.UserId, s.AssignedUserIds, {
                                    Equals: (x_1, y_1) => (x_1 === y_1),
                                    GetHashCode: stringHash,
                                });
                            }
                            else {
                                return true;
                            }
                        }, props.Storages)), (NoOptionsMessage_1 = t("tool.no_storage_found"), (Value_6 = ((storage_1 == null) ? undefined : ((someStorage = storage_1, {
                            label: someStorage.Name,
                            value: someStorage.Id,
                        }))), {
                            ComponentState: componentState,
                            IsClearable: false,
                            Label: unwrap(Label_5),
                            NoOptionsMessage: NoOptionsMessage_1,
                            OnChange: (storage_3) => {
                                props.Dispatch(new Msg(5, [value_13(storage_3).value]));
                            },
                            Options: Options_1,
                            PlaceholderKey: "",
                            TestId: "",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storage")),
                            Value: unwrap(Value_6),
                        })))))), delay(() => {
                            let matchValue_7;
                            return append(!props.IsEdit ? singleton(createElement(Input, {
                                ComponentState: componentState,
                                Label: t("tool.storage_space"),
                                OnChange: (arg_4) => {
                                    props.Dispatch(new Msg(6, [arg_4]));
                                },
                                TestId: "",
                                Value: (matchValue_7 = props.Consumable, (matchValue_7.tag === 1) ? "" : head(matchValue_7.fields[0].StockPerStorage).StorageSpace),
                            })) : empty(), delay(() => {
                                let Label_7, Value_8;
                                return !props.IsEdit ? singleton(createElement(NumberInput, (Label_7 = t("tool.storage_stock"), (Value_8 = map_1((value_7) => value_7, props.StorageStock), {
                                    ComponentState: componentState,
                                    Label: unwrap(Label_7),
                                    OnChange: (arg_5) => {
                                        props.Dispatch(new Msg(7, [arg_5]));
                                    },
                                    TestId: "",
                                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storageStock")),
                                    Value: unwrap(Value_8),
                                })))) : empty();
                            }));
                        }));
                    })),
                    Header: Header_1,
                    Id: "consumable-storage-section",
                }))))) : empty(), delay(() => {
                    let Header_2;
                    return append(singleton(createElement(FormSection, (Header_2 = t("general.category"), {
                        Content: ofArray([multiSelectWithLabel(selectLabelProps(props.Labels, props.Consumable, props.Dispatch, props.FormState.IsLoading), t("tool.label"), ""), createElement(TextButton, {
                            ComponentState: componentState,
                            Label: t("settings.create_new_label"),
                            OnClick: () => {
                                setIsNewCateogoryDialogOpen(true);
                            },
                            TestId: "add-new-category-button",
                            Variant: "default",
                        })]),
                        Header: Header_2,
                        Id: "consumable-label-section",
                    }))), delay(() => {
                        let Header_3, matchValue_8, matchValue_9, matchValue_10, matchValue_11;
                        return append(singleton(createElement(FormSection, (Header_3 = t("general.commercial_data"), {
                            Content: ofArray([createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier"),
                                OnChange: (arg_6) => {
                                    props.Dispatch(new Msg(13, [arg_6]));
                                },
                                TestId: "",
                                Value: (matchValue_8 = props.Consumable, (matchValue_8.tag === 1) ? matchValue_8.fields[0].CommercialData.Supplier : matchValue_8.fields[0].CommercialData.Supplier),
                            }), createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier_article_number"),
                                OnChange: (arg_7) => {
                                    props.Dispatch(new Msg(14, [arg_7]));
                                },
                                TestId: "",
                                Value: (matchValue_9 = props.Consumable, (matchValue_9.tag === 1) ? matchValue_9.fields[0].CommercialData.SupplierArticleNumber : matchValue_9.fields[0].CommercialData.SupplierArticleNumber),
                            }), createElement(CurrencyInput, {
                                ComponentState: componentState,
                                Label: t("general.purchase_price"),
                                OnChange: (arg_8) => {
                                    props.Dispatch(new Msg(16, [arg_8]));
                                },
                                TestId: "",
                                Value: unwrap(map_1((price) => (price / 100), (matchValue_10 = props.Consumable, (matchValue_10.tag === 1) ? matchValue_10.fields[0].CommercialData.PurchasePrice : matchValue_10.fields[0].CommercialData.PurchasePrice))),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.purchase_date"),
                                OnChange: (date) => {
                                    props.Dispatch(new Msg(17, [date]));
                                },
                                TestId: "",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "purchaseDate")),
                                Value: unwrap(props.PurchaseDate),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.gurantee_date"),
                                OnChange: (date_1) => {
                                    props.Dispatch(new Msg(18, [date_1]));
                                },
                                TestId: "",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "warrantyDate")),
                                Value: unwrap(props.WarrantyDate),
                            }), createElement(TextArea, {
                                ComponentState: componentState,
                                Label: t("general.comment"),
                                OnChange: (arg_9) => {
                                    props.Dispatch(new Msg(15, [arg_9]));
                                },
                                TestId: "",
                                Value: (matchValue_11 = props.Consumable, (matchValue_11.tag === 1) ? matchValue_11.fields[0].CommercialData.Comment : matchValue_11.fields[0].CommercialData.Comment),
                            })]),
                            Header: Header_3,
                            Id: "consumable-commercial-section",
                        }))), delay(() => {
                            let Header_4, matchValue_12, matchValue_13;
                            return append(props.IsCustomPropertiesEnabled ? singleton(createElement(FormSection, (Header_4 = t("settings.custom_property.nav_header"), {
                                Content: singleton_1(createElement(NewInventoryCustomProperties, {
                                    CustomProperties: (matchValue_12 = props.Consumable, (matchValue_12.tag === 1) ? matchValue_12.fields[0].CustomProperties : matchValue_12.fields[0].CustomProperties),
                                    FormState: props.FormState,
                                    LabelIds: (matchValue_13 = props.Consumable, (matchValue_13.tag === 1) ? map((arg_12) => (new LabelId(parse(arg_12))), matchValue_13.fields[0].CategoryIds) : map((arg_11) => (new LabelId(parse(arg_11))), matchValue_13.fields[0].CategoryIds)),
                                    OnSetCustomProperty: (arg_10) => {
                                        props.Dispatch(new Msg(11, [arg_10]));
                                    },
                                    PropertyConfigurations: props.PropertyConfigurations,
                                })),
                                Header: Header_4,
                                Id: "consumable-nav-header-section",
                            }))) : empty(), delay(() => {
                                let matchValue_14;
                                return append((matchValue_14 = FormState__getValidation(props.FormState, t, "global"), (matchValue_14 == null) ? (empty()) : singleton(createElement(ErrorAlert, {
                                    Label: matchValue_14,
                                }))), delay(() => {
                                    let elems_1;
                                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_1 = [createElement(Button, {
                                        ComponentState: componentState,
                                        Label: t("general.save"),
                                        OnClick: props.OnSave,
                                        TestId: "consumables-form-save-button",
                                        Variant: "primary",
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function EditConsumablesView(props) {
    let elems_1, elems, Label;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => initEditView(props.ConsumableId, props.UserData, undefined), update_1, (_arg, _arg_1) => {
    }), undefined, [props.ConsumableId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.ConsumablesForm;
    const matchValue_1 = state_1.PropertyConfigurations;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.Storages;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, consumable, isCustomPropertiesEnabled, labels, propertyConfigurations, storages;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumable = matchValue.fields[0];
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_1.fields[0];
        storages = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["id", "consumable-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["consumables", unwrapConsumableId(props.ConsumableId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "consumable.consumable_edit",
            }), createElement(ConsumablesForm, {
                Consumable: consumable,
                Customer: state_1.Customer,
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: true,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(9, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function NewConsumablesView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initNewView(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.ConsumablesForm;
    const matchValue_1 = state_1.PropertyConfigurations;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.Storages;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, consumable, isCustomPropertiesEnabled, labels, propertyConfigurations, storages;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumable = matchValue.fields[0];
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_1.fields[0];
        storages = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back_to_overview"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(singleton_1("consumables" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "consumable.create_new_consumable",
            }), createElement(ConsumablesForm, {
                Consumable: consumable,
                Customer: state_1.Customer,
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(8, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function CopyNewConsumablesView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initCopyView(props.ConsumableId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ConsumableId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.ConsumablesForm;
    const matchValue_1 = state_1.PropertyConfigurations;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.Storages;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, consumable, isCustomPropertiesEnabled, labels, propertyConfigurations, storages;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumable = matchValue.fields[0];
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_1.fields[0];
        storages = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["consumables", unwrapConsumableId(props.ConsumableId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "consumable.copy_consumable",
            }), createElement(ConsumablesForm, {
                Consumable: consumable,
                Customer: state_1.Customer,
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(8, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

