import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { PropertyConfigurationEnumValueId_Parse_Z721C83C5, PropertyConfigurationId as PropertyConfigurationId_5, PropertyConfigurationId_$reflection, LabelId_$reflection, PropertyConfigurationEnumValueId_$reflection } from "./Shared.js";
import { option_type, class_type, bool_type, union_type, list_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { parse, utcNow } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { contains, filter, ofArray, find, reduce, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrapPropertyConfigurationId } from "./Helper.js";
import { map as map_1 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { safeHash, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { split } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.2/Map.js";

export class PropertyConfigurationEnumValue extends Record {
    constructor(Id, Name, Color) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Color = Color;
    }
}

export function PropertyConfigurationEnumValue_$reflection() {
    return record_type("Shared.PropertyConfiguration.PropertyConfigurationEnumValue", [], PropertyConfigurationEnumValue, () => [["Id", PropertyConfigurationEnumValueId_$reflection()], ["Name", string_type], ["Color", string_type]]);
}

export class PropertyConfigurationType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Enum", "MultiEnum", "Date", "Bool", "Url"];
    }
}

export function PropertyConfigurationType_$reflection() {
    return union_type("Shared.PropertyConfiguration.PropertyConfigurationType", [], PropertyConfigurationType, () => [[], [["Item", list_type(PropertyConfigurationEnumValue_$reflection())]], [["Item", list_type(PropertyConfigurationEnumValue_$reflection())]], [], [], []]);
}

export class PropertyConfigurationVisibility extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Global", "Category", "User", "Employee", "ExternalPerson", "Subcontractor", "Room", "Project", "ConstructionSite", "Vehicle", "Student", "SchoolClass"];
    }
}

export function PropertyConfigurationVisibility_$reflection() {
    return union_type("Shared.PropertyConfiguration.PropertyConfigurationVisibility", [], PropertyConfigurationVisibility, () => [[], [["Item", LabelId_$reflection()]], [], [], [], [], [], [], [], [], [], []]);
}

export class PropertyConfiguration extends Record {
    constructor(Id, Name, Required, IsDeleted, Type, Visibility, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Required = Required;
        this.IsDeleted = IsDeleted;
        this.Type = Type;
        this.Visibility = Visibility;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function PropertyConfiguration_$reflection() {
    return record_type("Shared.PropertyConfiguration.PropertyConfiguration", [], PropertyConfiguration, () => [["Id", PropertyConfigurationId_$reflection()], ["Name", string_type], ["Required", bool_type], ["IsDeleted", bool_type], ["Type", PropertyConfigurationType_$reflection()], ["Visibility", PropertyConfigurationVisibility_$reflection()], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class CustomPropertyValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "EnumValue", "Date", "Bool"];
    }
}

export function CustomPropertyValue_$reflection() {
    return union_type("Shared.PropertyConfiguration.CustomPropertyValue", [], CustomPropertyValue, () => [[["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", bool_type]]]);
}

export class CustomProperty extends Record {
    constructor(Id, Value) {
        super();
        this.Id = Id;
        this.Value = Value;
    }
}

export function CustomProperty_$reflection() {
    return record_type("Shared.PropertyConfiguration.CustomProperty", [], CustomProperty, () => [["Id", string_type], ["Value", string_type]]);
}

export class CustomPropertyValueDto extends Record {
    constructor(Label, TextValue, BoolValue, DateValue, Type, PropertyConfigurationId) {
        super();
        this.Label = Label;
        this.TextValue = TextValue;
        this.BoolValue = BoolValue;
        this.DateValue = DateValue;
        this.Type = Type;
        this.PropertyConfigurationId = PropertyConfigurationId;
    }
}

export function CustomPropertyValueDto_$reflection() {
    return record_type("Shared.PropertyConfiguration.CustomPropertyValueDto", [], CustomPropertyValueDto, () => [["Label", string_type], ["TextValue", option_type(string_type)], ["BoolValue", option_type(bool_type)], ["DateValue", option_type(class_type("System.DateTime"))], ["Type", string_type], ["PropertyConfigurationId", string_type]]);
}

export class PropertyConfigurationEnabledResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Enabled", "NotBooked"];
    }
}

export function PropertyConfigurationEnabledResponse_$reflection() {
    return union_type("Shared.PropertyConfiguration.PropertyConfigurationEnabledResponse", [], PropertyConfigurationEnabledResponse, () => [[], []]);
}

export class CreatePropertyConfigurationDto extends Record {
    constructor(Name, Required, Type, Visibility) {
        super();
        this.Name = Name;
        this.Required = Required;
        this.Type = Type;
        this.Visibility = Visibility;
    }
}

export function CreatePropertyConfigurationDto_$reflection() {
    return record_type("Shared.PropertyConfiguration.CreatePropertyConfigurationDto", [], CreatePropertyConfigurationDto, () => [["Name", string_type], ["Required", bool_type], ["Type", PropertyConfigurationType_$reflection()], ["Visibility", PropertyConfigurationVisibility_$reflection()]]);
}

export class UpdatePropertyConfigurationDto extends Record {
    constructor(Name, Required, Type) {
        super();
        this.Name = Name;
        this.Required = Required;
        this.Type = Type;
    }
}

export function UpdatePropertyConfigurationDto_$reflection() {
    return record_type("Shared.PropertyConfiguration.UpdatePropertyConfigurationDto", [], UpdatePropertyConfigurationDto, () => [["Name", string_type], ["Required", bool_type], ["Type", PropertyConfigurationType_$reflection()]]);
}

export function Helper_initialPropertyConfiguration(propertyConfigurationType) {
    return new PropertyConfiguration(new PropertyConfigurationId_5(newGuid()), "", false, false, new PropertyConfigurationType(0, []), propertyConfigurationType, utcNow(), utcNow());
}

export function Helper_mapCustomProperties(customPropertyValues, categoryIds, propertyConfigurations) {
    return map((propertyConfiguration_1) => {
        const matchValue_1 = propertyConfiguration_1.Type;
        switch (matchValue_1.tag) {
            case 2: {
                const PropertyConfigurationId = unwrapPropertyConfigurationId(propertyConfiguration_1.Id);
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, map_1((property_1) => reduce((a, b) => (`${a}, ${b}`), map((valueId_1) => find((enumValue_2) => equals(enumValue_2.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(valueId_1)), matchValue_1.fields[0]).Name, ofArray(split(property_1.Value, [","], undefined, 0)))), FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), undefined, undefined, "multiEnum", PropertyConfigurationId);
            }
            case 0: {
                const PropertyConfigurationId_1 = unwrapPropertyConfigurationId(propertyConfiguration_1.Id);
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, map_1((property_2) => property_2.Value, FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), undefined, undefined, "text", PropertyConfigurationId_1);
            }
            case 3: {
                const PropertyConfigurationId_2 = unwrapPropertyConfigurationId(propertyConfiguration_1.Id);
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, undefined, undefined, map_1((property_3) => parse(property_3.Value), FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), "date", PropertyConfigurationId_2);
            }
            case 4: {
                const PropertyConfigurationId_3 = unwrapPropertyConfigurationId(propertyConfiguration_1.Id);
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, undefined, map_1((property_4) => (property_4.Value === "true"), FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), undefined, "bool", PropertyConfigurationId_3);
            }
            case 5: {
                const PropertyConfigurationId_4 = unwrapPropertyConfigurationId(propertyConfiguration_1.Id);
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, map_1((property_5) => property_5.Value, FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), undefined, undefined, "url", PropertyConfigurationId_4);
            }
            default:
                return new CustomPropertyValueDto(propertyConfiguration_1.Name, map_1((property) => find((enumValue) => equals(enumValue.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(property.Value)), matchValue_1.fields[0]).Name, FSharpMap__TryFind(customPropertyValues, propertyConfiguration_1.Id)), undefined, undefined, "enum", unwrapPropertyConfigurationId(propertyConfiguration_1.Id));
        }
    }, filter((propertyConfiguration) => {
        const matchValue = propertyConfiguration.Visibility;
        switch (matchValue.tag) {
            case 0:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                return true;
            default:
                return contains(matchValue.fields[0], categoryIds, {
                    Equals: equals,
                    GetHashCode: safeHash,
                });
        }
    }, propertyConfigurations));
}

