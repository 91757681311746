import de_1 from "date-fns/locale/de";
import en_GB from "date-fns/locale/en-GB";
import { currentLanguage } from "../LocalStorageHelper.js";
import { createElement } from "react";
import React from "react";
import { defaultOf, equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { toArray, ofArray, fold } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { map } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { day, month, year, create } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_datepicker from "react-datepicker";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { item, equalsWith } from "../fable_modules/fable-library-js.4.19.2/Array.js";

export const de = de_1;

export const en = en_GB;

export function currentLocale(_arg) {
    const matchValue = currentLanguage(undefined);
    switch (matchValue) {
        case "de":
            return de;
        case "en":
            return en;
        default:
            return de;
    }
}

export function DatePicker(props) {
    let elems;
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = toList(delay(() => append(singleton(["className", fold((a, b) => (`${a} ${b}`), "", ofArray(["bg-white", "border", "border-[#B6BECB]", "focus:border-primary", "focus:outline-none", "text-base", "font-normal", "rounded-lg", "h-10", "w-full", "px-3"]))]), delay(() => {
                let matchValue_1;
                return append((matchValue_1 = props.Value, (matchValue_1 == null) ? (empty()) : singleton(["selected", matchValue_1])), delay(() => append(singleton(["onChange", (date) => {
                    props.OnChange(map((date_1) => create(year(date_1), month(date_1), day(date_1), 0, 0, 0, 0, 1), date));
                }]), delay(() => append(singleton(["dateFormat", "dd.MM.yyyy"]), delay(() => append(singleton(["locale", currentLocale(undefined)]), delay(() => {
                    let matchValue_2;
                    return append((matchValue_2 = props.StartDate, (matchValue_2 == null) ? (empty()) : singleton(["startDate", matchValue_2])), delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = props.EndDate, (matchValue_3 == null) ? (empty()) : singleton(["endDate", matchValue_3])), delay(() => {
                            let matchValue_4;
                            return append((matchValue_4 = props.MinDate, (matchValue_4 == null) ? (empty()) : singleton(["minDate", matchValue_4])), delay(() => {
                                let matchValue_5;
                                return append((matchValue_5 = props.MaxDate, (matchValue_5 == null) ? (empty()) : singleton(["maxDate", matchValue_5])), delay(() => {
                                    let matchValue_6;
                                    return append((matchValue_6 = props.ExcludedDates, (matchValue_6 == null) ? (empty()) : singleton(["excludedDates", toArray(matchValue_6)])), delay(() => append(singleton(["isClearable", props.IsClearable]), delay(() => singleton(["disabled", props.ComponentState === "disabled"])))));
                                }));
                            }));
                        }));
                    }));
                }))))))));
            })))), Interop_reactApi.createElement(react_datepicker, createObj(props_2)))), delay(() => {
                const matchValue_8 = props.ValidationMessage;
                if (matchValue_8 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_8;
                    return singleton(createElement("div", {
                        className: join(" ", ["text-[#dc3545]", "text-xs", "mt-1"]),
                        children: message,
                    }));
                }
            }));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function RangeDatePicker(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return singleton((props_2 = toList(delay(() => append(singleton(["className", fold((a, b) => (`${a} ${b}`), "", ofArray(["bg-white", "border", "border-[#B6BECB]", "focus:border-primary", "focus:outline-none", "text-base", "font-normal", "rounded-lg", "h-10", "w-full", "px-3"]))]), delay(() => append(singleton(["selectsRange", true]), delay(() => append(singleton(["onChange", (dateRange) => {
                let testExpr, testExpr_1;
                let matchResult, startDate, endDate, startDate_1;
                if (dateRange == null) {
                    matchResult = 2;
                }
                else if ((testExpr = dateRange, !equalsWith(equals, testExpr, defaultOf()) && (testExpr.length === 1))) {
                    if (item(0, dateRange) != null) {
                        matchResult = 0;
                        startDate = item(0, dateRange);
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else if ((testExpr_1 = dateRange, !equalsWith(equals, testExpr_1, defaultOf()) && (testExpr_1.length === 2))) {
                    if (item(0, dateRange) == null) {
                        if (item(1, dateRange) == null) {
                            matchResult = 2;
                        }
                        else {
                            matchResult = 2;
                        }
                    }
                    else if (item(1, dateRange) != null) {
                        matchResult = 1;
                        endDate = item(1, dateRange);
                        startDate_1 = item(0, dateRange);
                    }
                    else {
                        matchResult = 0;
                        startDate = item(0, dateRange);
                    }
                }
                else {
                    matchResult = 2;
                }
                switch (matchResult) {
                    case 0: {
                        props.OnChange({
                            StartDate: startDate,
                        });
                        break;
                    }
                    case 1: {
                        props.OnChange({
                            EndDate: endDate,
                            StartDate: startDate_1,
                        });
                        break;
                    }
                    case 2: {
                        props.OnChange(undefined);
                        break;
                    }
                }
            }]), delay(() => append(singleton(["dateFormat", "dd.MM.yyyy"]), delay(() => append(singleton(["locale", currentLocale(undefined)]), delay(() => {
                let matchValue_1;
                return append((matchValue_1 = props.StartDate, (matchValue_1 == null) ? (empty()) : singleton(["startDate", matchValue_1])), delay(() => {
                    let matchValue_2;
                    return append((matchValue_2 = props.EndDate, (matchValue_2 == null) ? (empty()) : singleton(["endDate", matchValue_2])), delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = props.MinDate, (matchValue_3 == null) ? (empty()) : singleton(["minDate", matchValue_3])), delay(() => {
                            let matchValue_4;
                            return append((matchValue_4 = props.MaxDate, (matchValue_4 == null) ? (empty()) : singleton(["maxDate", matchValue_4])), delay(() => append(singleton(["isClearable", props.IsClearable]), delay(() => singleton(["disabled", props.ComponentState === "disabled"])))));
                        }));
                    }));
                }));
            })))))))))))), Interop_reactApi.createElement(react_datepicker, createObj(props_2))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function MonthYearDatePicker(props) {
    let elems;
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return singleton((props_2 = toList(delay(() => append(singleton(["className", fold((a, b) => (`${a} ${b}`), "", ofArray(["bg-white", "border", "border-[#B6BECB]", "focus:border-primary", "focus:outline-none", "text-base", "font-normal", "rounded-lg", "h-10", "w-full", "px-3"]))]), delay(() => {
                let matchValue_1;
                return append((matchValue_1 = props.Value, (matchValue_1 == null) ? (empty()) : singleton(["selected", matchValue_1])), delay(() => append(singleton(["onChange", props.OnChange]), delay(() => append(singleton(["dateFormat", "MM.yyyy"]), delay(() => append(singleton(["locale", currentLocale(undefined)]), delay(() => {
                    let matchValue_2;
                    return append((matchValue_2 = props.StartDate, (matchValue_2 == null) ? (empty()) : singleton(["startDate", matchValue_2])), delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = props.EndDate, (matchValue_3 == null) ? (empty()) : singleton(["endDate", matchValue_3])), delay(() => {
                            let matchValue_4;
                            return append((matchValue_4 = props.MinDate, (matchValue_4 == null) ? (empty()) : singleton(["minDate", matchValue_4])), delay(() => {
                                let matchValue_5;
                                return append((matchValue_5 = props.MaxDate, (matchValue_5 == null) ? (empty()) : singleton(["maxDate", matchValue_5])), delay(() => append(singleton(["isClearable", props.IsClearable]), delay(() => append(singleton(["disabled", props.ComponentState === "disabled"]), delay(() => singleton(["showMonthYearPicker", true])))))));
                            }));
                        }));
                    }));
                }))))))));
            })))), Interop_reactApi.createElement(react_datepicker, createObj(props_2))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

